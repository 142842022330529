  <template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Type</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Type"
              >
                <b-form-input
                  v-model="type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Type"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <table class="table table-bordered mt-3">
              <thead class="text">
                <tr>
                  <th>Rank<label style="color: red !important">*</label></th>
                  <th>Name</th>
                  <th>From<label style="color: red !important">*</label></th>
                  <th>To<label style="color: red !important">*</label></th>
                  <th>Days</th>
                  <th>
                    Incentive<label style="color: red !important">*</label>
                  </th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, id) in data" :key="id">
                  <td>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Rank"
                    >
                      <b-form-input
                        v-model="item.rank"
                        placeholder="Enter Rank"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </td>
                  <td>
                    <b-form-input
                      v-model="item.name"
                      placeholder="Enter Name"
                    />
                  </td>
                  <td>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="From"
                    >
                      <b-form-input
                        type="number"
                        v-model="item.from"
                        placeholder="Enter From"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </td>
                  <td>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="To"
                    >
                      <b-form-input
                        type="number"
                        v-model="item.to"
                        placeholder="Enter To"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </td>
                  <td>
                    <b-form-input
                      type="number"
                      v-model="item.days"
                      placeholder="Enter Days"
                    />
                  </td>
                  <td>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Incentive"
                    >
                      <b-form-input
                        type="number"
                        v-model="item.incentive"
                        placeholder="Enter Incentive "
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </td>

                  <td>
                    <b-button variant="outline-danger" @click="removeRow(id)">
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-button variant="primary" @click="addRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="flag"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import { BFormInput,BFormGroup, BForm, BRow, BCol, BButton } from "bootstrap-vue";
import axios from '@/components/axios';

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      type: "",
      flag: false,
      data: [
        {
          rank: "",
          name: "",
          from: "",
          to: "",
          days: "",
          incentive: "",
        },
      ],
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Commission") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/crm/commission");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/crm/commission");
          }
        }
      });
    }
    if (this.$route.params.id) {
      this.getEditValue();
      this.ifEdit = true;
    }
  },
  methods: {
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const accessToken = localStorage.getItem("accessToken");
      await axios({
        method: "GET",
        url: `${baseApi}/getcommissionById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data[0]);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.data = item.CommissionItem;
      this.type = item.type;
    },
    submitForm(e) {
      const data = {
        data: this.data,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.flag = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/commission/${this.$route.params.id}`
              : `${baseApi}/commission`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: {
              type: this.type,
              data: this.data,
            },
          })
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/master/crm/commission");
            })
            .catch((error) => {
              this.flag = false;
              console.log(error, "error");
            });
        }
      });
    },

    onClickBack() {
      this.$router.push("/master/crm/commission");
    },
    addRow() {
      this.data.push({
        rank: "",
        name: "",
        from: "",
        to: "",
        days: "",
        incentive: "",
      });
    },
    removeRow(index) {
      this.data.splice(index, 1);
    },
  },
};
</script>
